<template>
  <div class="px-6 py-2 flex flex-col">
    <header class="py-2 text-lg flex font-semibold text-text-alternate-1 pr-6">
      <button @click="$router.go(-1)">
        <LeftArrowIcon class="h-6 fill-current" />
      </button>
      <h2 class="mx-auto">Notifications</h2>
    </header>
    <BPageLoader v-if="isLoading" class="flex-grow" />
    <ul v-else>
      <li
        class="my-3 py-1 flex items-center text-text-alternate-1"
        v-for="{ id, notifications, notificationType, createdAt } in data"
        :key="id"
      >
        <span
          class="h-8 w-8 rounded-full flex-shrink-0 flex items-center font-bold justify-center text-sm"
          v-if="notificationTypes[notificationType] === 1"
          :style="{
            backgroundColor: randomColor({
              luminosity: 'bright',
              seed: `${notifications[0]}`,
            }),
            color: '#fff',
          }"
        >
          {{ notifications[0] }}
        </span>
        <BadgeIcon
          class="h-8 pl-0.5"
          style="fill: #A4D9FF"
          v-else-if="notificationTypes[notificationType] === 2"
        />
        <span
          class="bg-bg-alternate-3 h-8 w-8 rounded-full flex-shrink-0 flex items-center justify-center"
          v-else-if="notificationTypes[notificationType] === 3"
        >
          <MessageIcon class="h-4 fill-current text-text-alternate-4" />
        </span>
        <span
          class="bg-bg-alternate-3 h-8 w-8 rounded-full flex-shrink-0 flex items-center justify-center"
          v-else-if="notificationTypes[notificationType] === 4"
        >
          <UpdateIcon class="h-4 fill-current text-text-alternate-4" />
        </span>
        <span
          class="bg-bg-alternate-3 h-8 w-8 rounded-full flex-shrink-0"
          v-else
        />
        <span class="text-xs font-medium mx-4">
          {{ notifications }}
        </span>
        <span class="text-xs font-medium ml-auto whitespace-nowrap">
          {{ dayjs(createdAt).fromNow(true) }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { toRefs } from 'vue';
import { useStore } from 'vuex';
import { getUserNotifications } from '@/services/api';
import { useApi } from '@/cmp-functions/useApi';
import randomColor from 'randomcolor';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
const notificationTypes = {
  DONATION: 1,
  'DONATION MILESTONE': 2,
  MESSAGE: 3,
  WITHDRAWAL: 4,
  'LAST SEEN': 5,
};

export default {
  name: 'Notifications',

  setup() {
    const store = useStore();
    const authUser = store.state.auth.authData;

    const [response, fetchUserNotifications] = useApi(getUserNotifications);

    fetchUserNotifications(authUser.id);

    return {
      ...toRefs(response),
      notificationTypes,
      randomColor,
      dayjs,
    };
  },
};
</script>
